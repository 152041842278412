import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { AccessPipe } from '../../pipes/access/access.pipe';
import { StorageService } from '../storage.service';
@Injectable({
  providedIn: 'root'
})
export class AccessGuard implements CanActivate {
  access: any = {};
  path: any;
  constructor(private storage: StorageService, private router: Router) {
    this.storage.menuData.subscribe(accessModules => {
      this.access = accessModules;
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean  {
    const accessPipe = new AccessPipe(this.storage);
    const module = route.data['module'];
    const action = route.data['action'];



    if (
      action
        ? accessPipe.transform(module, action)
        : accessPipe.transform(module)
    ) {
      return true;
    } else {
      const url = 'admin';
      const tree: UrlTree = this.router.parseUrl(url);
      // return tree;
    }
  }
}
