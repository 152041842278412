import { Injectable } from '@angular/core';
import { HttpService } from '../../../shared/services/http.service';
import { map } from 'rxjs/operators';
import { API_URL } from '../../../shared/constants/app.constant';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(
    private _httpService: HttpService,
  ) { }

  contentList(queryParams?) {
    return this._httpService.get(API_URL.CONTENT_LIST, queryParams).pipe(
      map((data: any) => {
        return data;
      }),
    );
  }

  contentDetails(queryParams) {
    return this._httpService.get(API_URL.CONTENT_DETAILS, queryParams).pipe(
      map((data: any) => {
        return data;
      }),
    );
  }

  addContent(body) {
    return this._httpService.post(API_URL.ADD_CONTENT, body).pipe(
      map((data: any) => {
        return data;
      }),
    );
  }

  editContent(body) {
    return this._httpService.put(API_URL.EDIT_CONTENT, body).pipe(
      map((data: any) => {
        return data;
      })
    );

  }

  contentView(queryParams) {
    return this._httpService.get(API_URL.CONTENT_VIEW, queryParams).pipe(
      map((data: any) => {
        return data;
      }),
    );
	}

}
