import { Directive, HostListener, ElementRef, NgModule } from '@angular/core';

@Directive({
  selector: '[trim]'
})

export class TrimDirective {
  constructor(public el: ElementRef) {}
  @HostListener('keydown', ['$event']) keydown(e) {
    if (e.which === 32 && e.target.selectionStart === 0) {
      return false;
    }
  }
}

@NgModule({
  imports: [
  ],
  declarations: [TrimDirective],
  exports: [TrimDirective]
})
export class TrimSpaceDirModule { }

