import { Component, Inject, OnInit, NgModule } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import {
	MatSelectModule,
	MatInputModule,
} from '@angular/material';
import { CommonModule } from '@angular/common';
import { ValidationMessages } from '../../../shared/constants/messages';

export interface DialogData {
	status: number;
	title: string;
}

@Component({
	selector: 'app-modal',
	templateUrl: './dialog-document.component.html',
	styleUrls: ['./dialog-document.component.scss']
})

export class DialogDocumentComponent implements OnInit {
	public dialogForm: FormGroup;
	ValidationMessages = ValidationMessages;

	constructor(
		public dialogRef: MatDialogRef<DialogDocumentComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData
	) { }

	ngOnInit() {
		this.dialogForm = new FormGroup({
			reason: new FormControl('', [
				Validators.required,
				Validators.maxLength(45)
			])
		});
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	onClick(): void {
		this.dialogRef.close('');
	}

	onReject(): void {
		if (this.dialogForm.valid) {
			this.dialogRef.close(this.dialogForm.value);
		}
	}
}

@NgModule({
	imports: [MatSelectModule,
		MatInputModule, CommonModule, ReactiveFormsModule],
	exports: [DialogDocumentComponent],
	declarations: [DialogDocumentComponent],
	entryComponents: [DialogDocumentComponent]
})

export class DialogDocumentModule {

}
